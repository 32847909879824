import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      textAlign: "left",
      margin: 15,
    },
    position: {
      fontSize: 24,
      textAlign: "left",
    },
    company: {
      textAlign: "left",
      fontWeight: "bold",
      fontSize: 24,
    },
    responsibilitiesBody: {
      margin: 10,
    },
    responsibilityItem: {
      marginBottom: 5,
    },
    recognitionItem: {
      marginLeft: 10,
    },
    technologies: {
      marginTop: 10,
      fontSize: 24,
    },
    recognition: {
      marginTop: 10,
      marginBottom: 5,
      fontSize: 24,
    },
    tierTwo: {
      marginBottom: 5,
      marginLeft: 25,
    },
  })
);

const Job = (details) => {
  const classes = useStyles();

  const displayResponsibilities = (r) => {
    if (r.level === "1") {
      return (
        <li className={classes.responsibilityItem} key={r.description}>
          {r.description}
        </li>
      );
    }
    return (
      <div key={r.description} className={classes.tierTwo}>
        - {r.description}
      </div>
    );
  };

  const displayRecognition = (r) => {
    return (
      <li className={classes.responsibilityItem} key={r}>
        {r}
      </li>
    );
  }

  const j = details.details;

  return (
    <div className={classes.body}>
      <span className={classes.position}>{j.position} at </span>
      <span className={classes.company}>{j.company}</span>
      <span className={classes.position}> from {j.dates}</span>
      <div className={classes.responsibilitiesBody}>
        {j.responsibilities.map((a) => displayResponsibilities(a))}
      </div>
      <div className={classes.technologies}>Technologies: {j.technologies}</div>
      {j.recognition && <div> <div className={classes.recognition}>Recognition: </div> {j.recognition.map((a) => displayRecognition(a))}</div>}
    </div>
  );
};

export default Job;
