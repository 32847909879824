import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Job from "./job";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 15,
    },
    header: {
      fontSize: 24,
      textAlign: "left",
    },
    sectionHeader: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: 24,
      marginLeft: 25,
    },
    profile: {
      marginBottom: 10,
    },
    subSectionHeader: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: 16,
    },
    subSectionBody: {},
  })
);

const Resume = () => {
  const classes = useStyles();
  const resume = require("./data/resume.json");

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.header}>Chris O'Brien</div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.sectionHeader}>Profile</div>
          <div className={classes.profile}>{resume.profile}</div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.sectionHeader}>Technical Skills</div>
        </Grid>
        <Grid item md={4} xs={12}>
          <div className={classes.subSectionHeader}>Strengths</div>
          <div className={classes.subSectionBody}>{resume.strengths}</div>
        </Grid>
        <Grid item md={4} xs={12}>
          <div className={classes.subSectionHeader}>Experienced</div>
          <div className={classes.subSectionBody}>{resume.experienced}</div>
        </Grid>
        <Grid item md={4} xs={12}>
          <div className={classes.subSectionHeader}>Interests</div>
          <div className={classes.subSectionBody}>{resume.interests}</div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div className={classes.sectionHeader}>Professional Highlights</div>
        </Grid>
        {resume.experience.map((a) => (
          <Grid item key={a.position} xs={12}>
            <Job details={a} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Resume;
